<template>
  <b-container class="pt-3" id="getQuote">
    <div class="bg-colorBlue px-2 py-2 rounded">
      <h1 class="text-center mb-50 font-weight-bolder text-white">
        Get a Quote
      </h1>
      <validation-observer ref="contactUsPersonalCreateFormValidation">
        <b-form @submit.prevent>
          <div>
            <b-row class="justify-content-start">
              <b-col md="4">
                <b-form-group label-for="name">
                  <template #label>
                    <span class="text-light"
                      >Name <span class="text-danger">*</span></span
                    >
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    :rules="{ required }"
                  >
                    <b-form-input
                      id="name"
                      v-model="name"
                      v-restrict="regex"
                      :state="errors.length > 0 ? false : null"
                      name="name"
                      placeholder="Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label-for="mobilenumber">
                  <template #label>
                    <span class="text-light">
                      Contact Number <span class="text-danger">*</span></span
                    >
                  </template>

                  <VuePhoneNumberInput
                    v-model="mobileNo"
                    @update="onVuePhoneNumberUpdate"
                    :required="true"
                    color="#06bd46"
                    valid-color="#06bd46"
                    error-color="#ea5455"
                    :error="
                      mobileNoData !== null ? !mobileNoData['isValid'] : false
                    "
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label-for="email">
                  <template #label>
                    <span class="text-light"
                      >Email <span class="text-danger">*</span></span
                    >
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="email"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="">
              <b-col md="12">
                <b-form-group :label-for="message">
                  <template #label>
                    <span class="font-weight-bolder text-light">Message</span>
                  </template>
                  <validation-provider #default="{ errors }" name="Messsage">
                    <!-- <b-form-textarea
                      id="message"
                      v-model="message"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Message"
                    /> -->
                    <b-form-textarea
                      id="textarea"
                      v-model="message2"
                      placeholder="Enter your message"
                      rows="3"
                      max-rows="6"
                    >
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-textarea>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="text-center">
            <b-button
              type="submit"
              variant="colorGreen"
              class="text-white"
              @click="validateForm"
            >
              GET A QUOTE NOW
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueSelectPaginated from "@/components/ui/VueSelectPaginated.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { Country, State, City } from "country-state-city";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import util from "@/util.js";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueSelectPaginated,
    ToastificationContent,
    Country,
    State,
    City,
    VuePhoneNumberInput,
  },
  mixins: [util],
  data() {
    return {
      email: "",
      mobileNo: "",
      mobileNoData: null,
      name: "",
      regex: /^[a-zA-Z ]*$/,
      required,
      message2: "",
      message: "",
    };
  },
  methods: {
    ...mapActions({
      createQuote: "appData/createQuote",
    }),
    async validateForm() {
      const success =
        await this.$refs.contactUsPersonalCreateFormValidation.validate();
      if (success && this.mobileNoData["isValid"]) {
        await this.submit();
      }
    },
    async beforeChange() {
      const validationObserver = this.$refs.contactUsCreateFormValidation;
      const isValid = await validationObserver.validate();
      if (!isValid) {
        this.isFormValid = false;
        return false;
      } else {
        this.isFormValid = true;
        return true;
      }
    },

    onVuePhoneNumberUpdate(data) {
      this.mobileNoData = data;
    },
    async submit() {
      try {
        let formattedMobileNo = this.mobileNoData[
          "formatInternational"
        ].replace(/[\s()+-]/g, "");
        const formData = new FormData();
        formData.append("full_name", this.name);
        formData.append("email", this.email);
        formData.append("mobile", formattedMobileNo);
        formData.append("message", this.message2);

        const res = await this.createQuote(formData);
        if (res.status === 200) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Request Sent Successfully",
                variant: "success",
                icon: "BellIcon",
              },
            },
            {
              position: "top-right",
            }
          );
          this.reset();
          this.$router.push({ name: "Home" });
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    reset() {
      this.name = "";
      this.email = "";
      this.message2 = "";
      this.mobileNo = "";
    },
  },
};
</script>

<style scoped></style>
